var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", sm6: "", "offset-sm3": "" } },
        [
          _c(
            "v-card",
            [
              _c("v-img", {
                attrs: {
                  src: "https://cdn.vuetifyjs.com/images/cards/desert.jpg",
                  "aspect-ratio": "2.75",
                },
              }),
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", [
                  _c("h3", { staticClass: "headline mb-0" }, [
                    _vm._v("Access denied"),
                  ]),
                  _c("div", [
                    _vm._v("You don't have permission to access this page"),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }